import React from 'react'

import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Table,
  Button,
  Label,
  Input,
  FormGroup,
  Form,
  CardBody,
  Card,
  CardHeader,
  FormFeedback,
} from 'reactstrap'
import Select from 'react-select'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import s from './UpdateOrder.module.scss'
import { connect } from 'react-redux'
import {
  updatePackageByCode,
  fetchPackage
} from '../../actions/package'
import { fetchUserByID } from '../../actions/user'

import PropTypes from 'prop-types'
import jwt from 'jsonwebtoken'
import { DOC_TYPE, PACK_TYPE, SHIPPING_SERVICES, SHIPPING_SERVICES_SPECIFIC,
  USER_ROLE, SHIPPING_SERVICES_5500 } from '../../constants'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Link } from 'react-router-dom'
import { Country, City } from 'country-state-city'
import validator from 'validator'
import Rounding from '../../utils/rounding'
import axios from "axios";

let timerId = null

class NewUpdateOrder extends React.Component {
  initBillValidation = {
    errMsg: '',

    senderCompanyName: false,
    senderAddress: false,
    senderPhone: false,
    senderName: false,
    senderEmail: false,

    receiverCompanyName: false,
    receiverName: false,
    receiverPhone: false,
    receiverCountry: false,
    receiverPostalCode: false,
    receiverCity: false,
    receiverAddr1: false,
    receiverAddr2: false,
    packContent: false,

    docCount: false,
    docWeight: false,

    logisticService: false,
    packs: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      disableSubmitBtn: false,

      validDkMess: '',
      currUser: {},
      packList: [
        {
          quantity: '',
          type: '',
          length: '',
          width: '',
          height: '',
          weight: '',
          convertRatio: '',
          chargedWeight: '',
        },
      ],
      packInvoiceList: [
        {
          description: '',
          unit: '',
          quantity: '',
          unitPrice: '',
          subTotal: '',
        },
      ],
      billInfo: {
        companyUserID: '',
        senderCompanyName: '',
        senderName: '',
        senderContact: '',
        senderPhone: '',
        senderEmail: '',
        senderCountry: '',
        receiverCompanyName: '',
        receiverName: '',
        receiverPhone: '',
        receiverEmail: '',
        receiverCountry: '',
        receiverPostalCode: '',
        receiverCity: '',
        receiverProvince: '',
        receiverAddr1: '',
        receiverAddr2: '',
        receiverAddr3: '',
        logisticService: '',
        referenceCode: '',
        docCount: 0,
        docWeight: 0,
        packContent: '',
        packInvoiceValue: '',
        packageType: DOC_TYPE,
        kgCode: props.match.params.billId,
        hawbCode: '',
        totalPrice: 0,
        invoices: JSON.stringify([
          {
            description: '',
            unit: '',
            quantity: '',
            unitPrice: '',
            subTotal: '',
          },
        ]).replaceAll(`\"`, `\\"`),
        packs: JSON.stringify([
          {
            quantity: '',
            type: '',
            length: '',
            width: '',
            height: '',
            weight: '',
            convertRatio: '',
            chargedWeight: '',
          },
        ]).replaceAll(`\"`, `\\"`),
        invoiceExportFormat: '',
      },

      billValidation: this.initBillValidation,
      packsDetailsValidation: [
        {
          errMsg: '',
          quantity: false,
          length: false,
          width: false,
          height: false,
          weight: false,
        },
      ],
      editBillup: {
        note: '',
        paymentType: '',
        groupType: '',
        statusType: '',
        statusPayment: '',
        weight: '',
        weight2: '',
      },
      listServices: [],
      selectedCustomerKH: {
        name: '',
        khCode: '',
        phone: '',
        address: ''
      },
      listCustomer: [],
      listCustomerKH: [],
      selectedUser: '',
      groupSelected: [],
    }

    this.handlePackageTypeChange = this.handlePackageTypeChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onPackInfoChange = this.onPackInfoChange.bind(this)
    this.onRemoveRecord = this.onRemoveRecord.bind(this)
    this.onRemovePackInvoiceRecord = this.onRemovePackInvoiceRecord.bind(this)
    this.addPackage = this.addPackage.bind(this)
    this.addPackageInvoice = this.addPackageInvoice.bind(this)
    this.validateBillInfo = this.validateBillInfo.bind(this)
    this.onEditBillupChange = this.onEditBillupChange.bind(this)
    this.onImageChange = this.onImageChange.bind(this)
    this.getListCustomerKH = this.getListCustomerKH.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeKH = this.handleInputChangeKH.bind(this);
    this.onSelectedCustomerKH = this.onSelectedCustomerKH.bind(this);
  }

  /* eslint-disable */
  static propTypes = {
    package: PropTypes.any,
    isCreating: PropTypes.bool,
    errMsg: PropTypes.string,
    type: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
  }
  /* eslint-enable */

  static defaultProps = {
    package: {},
    isCreating: false,
    errMsg: '',
    type: '',
  }

  async getListCustomerKH(params = { searchKH: ''}) {
    try {
      let url = `/get-list-customer?page=${params.page || 1}&size=10&search=${params.searchKH}`
      const res = await axios.get(url,)
      if (res && res.data) {
        const list = res.data.list.map(i => {
          return {
            ...i,
            value: i.id,
            label: `KHTP${i.id} - ${i.name}`
          }
        })
        this.setState({listCustomerKH: list})
        // this.onSelectedCustomer(list[0])

      }
    } catch (e) {
      console.error(e)
    }
  }

  onInfoChange = (e) => {
    const { name, value } = e.target
    var newInfo = this.state.billInfo
    newInfo[name] = value
    this.setState(newInfo)
    this.validateBillOnInfoChange(name, value)
    if (name === 'logisticService' && this.state.billInfo.packageType === PACK_TYPE) {

      let ratioConstant = 5000;
      const currentService = this.state.listServices.find((e) => e.name === value);
      if (currentService) {
        ratioConstant = currentService.convertNumber;
      }

      // let ratioConstant = this.state.currUser.role === USER_ROLE.CUSTOMER &&
      // value && SHIPPING_SERVICES_SPECIFIC.includes(value.toUpperCase()) ? 6000 : 5000
      //
      // ratioConstant = (this.state.currUser?.companyName.indexOf('TIN PHAT EXPRESS CO.,LTD') !== -1)
      // && value && SHIPPING_SERVICES_5500.includes(value.toUpperCase()) ? 5500 : ratioConstant
      //
      // ratioConstant = (this.state.currUser?.companyName.indexOf('KANGO') !== -1) ||
      // this.state.currUser.role === USER_ROLE.SALES ? 5000 : ratioConstant

      const list = [...this.state.packList]
      for (let index = 0; index < list.length; index++) {
        list[index]["convertRatio"] = Rounding((list[index]["length"] * list[index]["width"] * list[index]["height"]) / ratioConstant)
        list[index]['chargedWeight'] = list[index]['weight'] > list[index]['convertRatio']
        ? Rounding(parseFloat(list[index]['weight']).toFixed(1) * parseInt(list[index]['quantity']))
        : Rounding(list[index]['convertRatio'] * list[index]['quantity'])
      }
      this.setState((prevState) => ({
        packList: list,
        billInfo: {
          ...prevState.billInfo,
          packs: JSON.stringify(list).replaceAll(`\"`, `\\"`),
        },
      }))
    }
  }

  onSelectChange = (value, action) => {
    var newInfo = this.state.billInfo
    newInfo[action.name] = value.value
    this.setState(newInfo)
    this.validateBillOnInfoChange(action.name, value)
  }


  onPackInfoChange = (e, index) => {
    // validate here
    const { name, value } = e.target
    const list = [...this.state.packList]
    list[index][name] = value

    // update convertRatio
    if (name === 'length' || name === 'width' || name === 'height') {
      const { logisticService } = this.state.billInfo
      let ratioConstant = 5000;
      const currentService = this.state.listServices.find((e) => e.name === logisticService);
      if (currentService) {
        ratioConstant = currentService.convertNumber;
      }
      // let ratioConstant = this.state.currUser.role === USER_ROLE.CUSTOMER &&
      // logisticService && SHIPPING_SERVICES_SPECIFIC.includes(logisticService.toUpperCase()) ? 6000 : 5000
      //
      // ratioConstant = (this.state.currUser?.companyName.indexOf('TIN PHAT EXPRESS CO.,LTD') !== -1)
      // && logisticService && SHIPPING_SERVICES_5500.includes(logisticService.toUpperCase()) ? 5500 : ratioConstant
      //
      // ratioConstant = (this.state.currUser?.companyName.indexOf('KANGO') !== -1) ||
      // this.state.currUser.role === USER_ROLE.SALES ? 5000 : ratioConstant

      list[index]["convertRatio"] = Rounding(
        (list[index]["length"] * list[index]["width"] * list[index]["height"]) / ratioConstant
      );
    }
    // update charged weight
    list[index]['weight'] > list[index]['convertRatio']
      ? (list[index]['chargedWeight'] = Rounding(
          parseFloat(list[index]['weight']).toFixed(1) *
            parseInt(list[index]['quantity']),
        ))
      : (list[index]['chargedWeight'] = Rounding(
          list[index]['convertRatio'] * list[index]['quantity'],
        ))

    this.setState((prevState) => ({
      packList: list,
      billInfo: {
        ...prevState.billInfo,
        packs: JSON.stringify(list).replaceAll(`\"`, `\\"`),
      },
    }))
    this.validatePackOnPackInfoChange(name, value, index)
  }

  validatePacksDetails(packsDetails) {
    let validations = this.state.packsDetailsValidation
    let isValid = true

    packsDetails.forEach((pack, i) => {
      let errMsgs = []

      if (pack.quantity <= 0) {
        isValid = false

        validations[i].quantity = true
        errMsgs.push('Quantity is required.')
      } else {
        validations[i].quantity = false
      }

      if (pack.height <= 0) {
        isValid = false

        validations[i].height = true
        errMsgs.push('Height is required.')
      } else {
        validations[i].height = false
      }

      if (pack.width <= 0) {
        isValid = false

        validations[i].width = true
        errMsgs.push('Width is required.')
      } else {
        validations[i].width = false
      }

      if (pack.length <= 0) {
        isValid = false

        validations[i].length = true
        errMsgs.push('Length is required.')
      } else {
        validations[i].length = false
      }

      if (pack.weight <= 0) {
        isValid = false
        validations[i].weight = true
        errMsgs.push('Weight is required.')
      } else {
        validations[i].weight = false
      }

      if (pack.weight > 0 && pack.weight < 30) {
        const dec = pack.weight - parseInt(pack.weight)
        if (dec.toFixed(1) != 0.5 && dec.toFixed(1) != 0.0) {
          isValid = false

          validations[i].weight = true
          errMsgs.push(
            'Hãy làm tròn số thập phân .5 hoặc số nguyên. VD: 1.5 hoặc 1',
          )
        } else {
          validations[i].weight = false
        }
      } else {
        const dec = pack.weight - parseInt(pack.weight)
        if (dec.toFixed(1) != 0.0) {
          isValid = false

          validations[i].weight = true
          errMsgs.push('Hãy làm tròn số nguyên. VD: 30.5 -> 30 hoặc 31')
        } else {
          validations[i].weight = false
        }
      }

      validations[i].errMsg = errMsgs.join(' ')
    })

    this.setState((prevState) => ({
      ...prevState,
      packsDetailsValidation: validations,
    }))

    return isValid
  }

  validatePackOnPackInfoChange(name, value, i) {
    let validations = this.state.packsDetailsValidation

    if (name === 'quantity' && value <= 0) {
      validations[i].quantity = true
    } else {
      validations[i].quantity = false
    }

    if (name === 'height' && value <= 0) {
      validations[i].height = true
    } else {
      validations[i].height = false
    }

    if (name === 'width' && value <= 0) {
      validations[i].width = true
    } else {
      validations[i].width = false
    }

    if (name === 'length' && value <= 0) {
      validations[i].length = true
    } else {
      validations[i].length = false
    }

    if (name === 'weight' && value <= 0) {
      validations[i].weight = true
    } else {
      validations[i].weight = false
    }

    this.setState((prevState) => ({
      ...prevState,
      packsDetailsValidation: validations,
    }))
  }

  onImageChange = (e, i) => {
    const { name, files } = e.target;
    const list = [...this.state.packList];
    list[i][name] = e.target.files[0];
    this.setState({packList: list});
  }
  onChangeInvoice(e, i) {
    const { name, value } = e.target
    const list = [...this.state.packInvoiceList]
    if (name === 'receiverCountry') {
      // value is country object
      list[i][name] = value.name
    } else {
      list[i][name] = value
    }

    list[i]['subTotal'] = list[i]['quantity'] * list[i]['unitPrice'] // calculate subTotal

    // calculate invoice value
    let invVal = 0
    if (name === 'packInvoiceValue') {
      invVal = value
    } else {
      invVal = (
        list.reduce(function (a, b) {
          return a + parseFloat(b['subTotal'])
        }, 0) || 0
      ).toFixed(1)
    }

    this.setState((prevState) => ({
      packInvoiceList: list,
      billInfo: {
        ...prevState.billInfo,
        packInvoiceValue: invVal,
      },
    }))
  }

  onRemoveRecord = (index) => {
    const list = [...this.state.packList]

    confirmAlert({
      title: 'Delete Confirmation',
      message: 'Are you sure to delete this Pack?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            list.splice(index, 1)
            this.setState({ packList: list })
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    })
  }

  onRemovePackInvoiceRecord = (index) => {
    const list = [...this.state.packInvoiceList]

    confirmAlert({
      title: 'Delete Confirmation',
      message: 'Are you sure to delete this Invoice?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            list.splice(index, 1)
            this.setState({ packInvoiceList: list })
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    })
  }

  addPackage = () => {
    this.setState((prevState) => ({
      packList: [
        ...prevState.packList,
        {
          quantity: '',
          type: '',
          length: '',
          width: '',
          height: '',
          weight: '',
          convertRatio: '',
          chargedWeight: '',
        },
      ],
      packsDetailsValidation: [
        ...prevState.packsDetailsValidation,
        {
          errMsg: '',
          quantity: false,
          length: false,
          width: false,
          height: false,
          weight: false,
        },
      ],
    }))
  }
  addPackageInvoice = () => {
    this.setState((prevState) => ({
      packInvoiceList: [
        ...prevState.packInvoiceList,
        {
          description: '',
          unit: '',
          quantity: '',
          unitPrice: '',
          subTotal: '',
        },
      ],
    }))
  }

  validateBillInfo(info) {
    let validationState = this.state.billValidation
    let errors = []
    // if (info.senderCompanyName === '') {
    //   validationState.senderCompanyName = true
    //   errors.push('your company name')
    // } else validationState.senderCompanyName = false
    if (info.senderName === '') {
      validationState.senderName = true
      errors.push('your name')
    } else validationState.senderName = false
    if (info.senderPhone === '') {
      validationState.senderPhone = true
      errors.push('your phone')
    } else validationState.senderPhone = false
    if (this.state.currUser && this.state.currUser.address === '') {
      validationState.senderAddress = true
      errors.push("your company's address")
    } else validationState.senderAddress = false
    if (info.senderEmail !== '' && !validator.isEmail(info.senderEmail)) {
      validationState.senderEmail = true
      errors.push('sender email')
    } else validationState.senderEmail = false

    // if (info.receiverCompanyName === '') {
    //   validationState.receiverCompanyName = true
    //   errors.push("receiver company's name")
    // } else validationState.receiverCompanyName = false
    if (info.receiverName === '') {
      validationState.receiverName = true
      errors.push('receiver name')
    } else validationState.receiverName = false
    if (info.receiverPhone === '') {
      validationState.receiverPhone = true
      errors.push('receiver phone')
    } else validationState.receiverPhone = false
    if (info.receiverCountry === '') {
      validationState.receiverCountry = true
      errors.push('receiver country')
    } else validationState.receiverCountry = false
    if (info.receiverPostalCode === '') {
      validationState.receiverPostalCode = true
      errors.push('receiver postal code')
    } else validationState.receiverPostalCode = false
    if (info.receiverCity === '') {
      validationState.receiverCity = true
      errors.push('receiver city')
    } else validationState.receiverCity = false
    // if (info.receiverAddr1 === '') {
    //   validationState.receiverAddr1 = true
    //   errors.push('receiver address 1')
    // } else validationState.receiverAddr1 = false
    // if (info.receiverAddr2 === '') {
    //   validationState.receiverAddr2 = true
    //   errors.push('receiver address 2')
    // } else validationState.receiverAddr2 = false
    // if (info.packageType === PACK_TYPE && info.packContent === '') {
    //   validationState.packContent = true
    //   errors.push('package name')
    // } else validationState.packContent = false

    if (info.logisticService === '') {
      validationState.logisticService = true
      errors.push('logistic service')
    } else validationState.logisticService = false

    if (info.packageType === DOC_TYPE && info.docCount <= 0) {
      validationState.docCount = true
      errors.push('doc count')
    } else validationState.docCount = false
    if (info.packageType === DOC_TYPE && info.docWeight < 0.01) {
      validationState.docWeight = true
      errors.push('doc weight')
    } else validationState.docWeight = false

    if (errors.length > 0) {
      validationState.errMsg = 'Please input valid ' + errors.join(', ')
      this.setState({ billValidation: validationState })
      return false
    }
    return true
  }

  validateBillOnInfoChange(name, value) {
    let validationState = this.state.billValidation

    let arrStr = [
      'senderCompanyName',
      'senderName',
      'senderPhone',
      'receiverCompanyName',
      'receiverName',
      'receiverPhone',
      'receiverCountry',
      'receiverPostalCode',
      'receiverCity',
      'receiverAddr1',
      'receiverAddr2',
      'logisticService',
    ]
    if (arrStr.indexOf(name) && value === '') {
      validationState[name] = true
    } else validationState[name] = false

    if (name === 'senderEmail' && value !== '' && !validator.isEmail(value)) {
      validationState.senderEmail = true
    } else validationState.senderEmail = false

    if (name === 'docCount' && value <= 0) {
      validationState.docCount = true
    } else validationState.docCount = false
    if (name === 'docWeight' && value < 0.01) {
      validationState.docWeight = true
    } else validationState.docWeight = false

    this.setState((prevState) => ({
      ...prevState,
      billValidation: validationState,
    }))
  }

  async onSubmit(e) {
    if (
      !this.validatePacksDetails(this.state.packList) &&
      this.state.packageType === PACK_TYPE
    ) {
      for (const validation of this.state.packsDetailsValidation) {
        if (validation.errMsg !== '') {
          toast.error(validation.errMsg)
        }
      }

      return
    }

    if (!this.validateBillInfo(this.state.billInfo)) {
      toast.error(this.state.billValidation.errMsg)
      return
    }

    if (this.state.billInfo.packageType === PACK_TYPE) {
      const list = [...this.state.packList]
      const formData = new FormData()
      for (let i = 0; i < list.length; i++) {
        // check typeof list[i]?.images === 'object' to avoid error
        if (list[i]?.images && typeof list[i]?.images === 'object') {
          formData.append('photos', list[i]?.images)
        } else {
          formData.append('photos', '')
        }
      }
      try {
        const {data = []} = await axios({
          method: "post",
          url: "/upload-pickup",
          data: formData,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });

        // update images to packList
        let imageArr = []
        for (let i = 0; i < list.length; i++) {
          if (list[i]?.images && typeof list[i]?.images === 'object') {
            imageArr.push(data[i])
          } else {
            imageArr.push('')
            data.unshift('')
          }
        }
        for (let i = 0; i < list.length; i++) {
          if (list[i]?.images && typeof list[i]?.images === 'object') {
            console.log(imageArr, i)
            list[i].images = imageArr[i]
          }
        }

        // update packList
        this.setState({
          packList: list,
        })
      } catch (e) {
        console.log('error', e)
      }
    }

    // if (!document.getElementsByName('checkDk')[0].checked) {
    //   this.setState({
    //     validDkMess: 'You must confirm reading the Terms of Use',
    //   })
    //   return
    // }
		const packs = JSON.stringify(this.state.packList).replaceAll(`\"`, `\\"`)
		const invoices =  JSON.stringify(this.state.packInvoiceList).replaceAll(`\"`, `\\"`)
    await this.setState({
      validDkMess: '',
      billValidation: this.initBillValidation,
      disableSubmitBtn: true,
			billInfo: {
				...this.state.billInfo,
				invoices,
				packs,
			}
    })
    try {
      if (this.state.billInfo.packageType === PACK_TYPE) {
       // get lenght or hawb and split by ,
       // compare with packList length
       // and auto increase or decrease hawb length by packList length
        let hawb = this.state.billInfo.hawbCode
        let hawbArr = hawb.split(',')
        let packListLength = this.state.packList.length
        let hawbLength = hawbArr.length
        if (packListLength > hawbLength) {
          const lengthDiff = packListLength - hawbLength
          for (let i = 0; i < lengthDiff; i++) {
            // hawbArr increase number by 1
            hawbArr.push(parseInt(hawbArr[hawbArr.length - 1]) + 1)
          }
        } else if (packListLength < hawbLength) {
          const lengthDiff = hawbLength - packListLength
          for (let i = 0; i < lengthDiff; i++) {
            hawbArr.pop()
          }
        }
        this.setState({
          billInfo: {
            ...this.state.billInfo,
            hawbCode: hawbArr.join(','),
          },
        })
      }

     await updatePackageByCode(this.state.billInfo)
      if (this.state.currUser.role !== USER_ROLE.OPS) {
        let data = this.state.editBillup
        if (this.state.selectedCustomerKH?.id) {
          data = {...data, customerId: this.state.selectedCustomerKH?.id}
        }
        if (data && data.id){
          delete data.id
        }
        try {
          let packageGroup = this.state.groupSelected.map(e => e.value).join(', ')
          await this.updatePackageInfo(this.state.billInfo.kgCode, {
            ...data,
            group: packageGroup,
          })
        } catch (e) {
          console.log(e)
        }
      }
     confirmAlert({
      title: "Thành công",
      message: "Chỉnh sửa của bạn đã được cập nhật",
      buttons: [
        {
          label: "Đóng",
          onClick: () => {
            // let path = `/app/packet`;
            // this.props.history.push(path);
            this.props.history.goBack();
          },
        }
      ],
    });
    } catch (error) {
      console.log(error)
      toast.error(error?.[0]?.message);
    }

    e.preventDefault()
		this.setState({disableSubmitBtn: false})
  }
  jsonEscape(str)  {
    return str.replace(/\n/g, ' ');
  }
  async componentDidMount() {
    let { billId } = this.props.match.params
    const data = await fetchPackage(billId)

    try {
      if (data) {
        this.setState({ billInfo: { ...data } })
        console.log(data)
        this.setState({ packList: JSON.parse(data?.packs) })
        this.setState({ packInvoiceList: JSON.parse(this.jsonEscape(data.invoices)) })
      }
    } catch (e) {
        this.setState({ packInvoiceList: [] })
    }
    //const currUser = await fetchUserByID(data.companyUserID)
    let token = localStorage.getItem("id_token");
    let currUser = jwt.decode(token);
    await this.getListCustomerKH()
    await this.getPackageInfo(billId)


    const itemsLength = this.state.packList.length
    const validate = this.state.packsDetailsValidation
    for (let i = 0; i < itemsLength; i++) {
      validate.push({
        errMsg: '',
        quantity: false,
        length: false,
        width: false,
        height: false,
        weight: false,
      })
    }

    this.setState({ currUser: currUser })
    let newState = this.state.billInfo
    newState.companyUserID = currUser?.id
    //newState.senderCompanyName = currUser.companyName

    this.setState({ billInfo: newState })
    this.getAllService()
  }

  handleInputChangeKH(e) {
    const inputValue = e;
    // Clear any existing timer
    clearTimeout(timerId);
    let self = this
    // Set a new timer to delay the API call
    timerId = setTimeout(() => {
      self.getListCustomerKH({ searchKH: inputValue });
    }, 500);
  }

  onSelectedCustomerKH = (customer) => {
    if (customer) {
      const {id} = customer
      const temp = this.state.listCustomerKH.filter(e => e.id == id)?.[0]
      if (temp) {
        this.setState({selectedCustomerKH: temp})
        // this.getListCustomer({search: this.state.search})
      }
    }
  }


  async getAllService(params = {}) {
    try {
      let url = `/get-all-service-name`
      const res = await axios.get(url)
      if (res && res.data) {
        let listServices = res.data
        if (this.state.currUser.role === USER_ROLE.OPS) {
          listServices = res.data.filter(e => e.type === 'fwd')
        }
        this.setState({listServices: listServices})
      }
    } catch (e) {
      console.error(e)
    }
  }
  getPackageInfo = async (id) => {
    try {
      const data = await axios.get(`/get-packageinfo/${id}`)
      if (data) {
        // check data.data.customerId and set to this.state.selectedCustomerKH?.id
        if (data.data.customerId) {
          const selectedCustomerKH = this.state.listCustomerKH.find(e => e.id === data.data.customerId)
          if (selectedCustomerKH) {
            this.setState({selectedCustomerKH})
          }
        }
        this.setState({ editBillup: data.data })
        if (data?.data?.group) {
          let arr = data.data.group.split(', ')
          // define arr label, value
          let arrGroupSelected = []
          arr.forEach(e => {
            arrGroupSelected.push({label: e, value: e})
          })
          this.setState({groupSelected: arrGroupSelected})
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  updatePackageInfo = async (id, data) => {
    try {
      await axios.post(`/update-packageinfo/${id}`, data)
    } catch (e) {
      console.log(e)
    }
  }

  handlePackageTypeChange(e) {
    let newBillInfo = this.state.billInfo
    newBillInfo.packageType = e.target.value
    this.setState(newBillInfo)
  }

  getInvoiceVal(e) {
    this.setState((prevState) => ({
      billInfo: {
        ...prevState.billInfo,
        packInvoiceValue: this.state.packInvoiceList.reduce(function (a, b) {
          return a + parseFloat(b['subTotal'])
        }, 0),
      },
    }))
  }

  handleChange = (newValue, actionMeta) => {
    console.group('Value Changed')
    console.log(newValue)
    console.log(`action: ${actionMeta.action}`)
    console.groupEnd()
  }
  handleInputChange = (inputValue, actionMeta) => {
    console.group('Input Changed')
    console.log(inputValue)
    console.log(`action: ${actionMeta.action}`)
    console.groupEnd()
  }

  onEditBillupChange(e) {
    const {name, value} = e.target;
    let editBillupInfo = this.state.editBillup
    editBillupInfo[name] = value
    if (name === 'totalPrice') {
      let billInfo = this.state.billInfo
      billInfo.totalPrice = value
      this.setState({billInfo: billInfo})
    }
    this.setState({editBillup: editBillupInfo})
  }

  handleChangeType(e) {
    this.setState({groupSelected: e})
  }

  render() {
    const paymentTypeOption = {
      CASH: "Tiền mặt",
      BANK: "Chuyển khoản",
      BANK2: "Tiền mặt và Chuyển khoản",
    }
    const groupTypeOption = {
      CASH: "Hàng thường",
      BANK: "Hàng khó",
    }
    const groupTypeOption2 = [
      {
        label: "Hàng liên quan đến thịt - trứng - sữa - hạt giống",
        value: "Hàng liên quan đến thịt - trứng - sữa - hạt giống",
      },
      {
        label: "Hàng yến",
        value: "Hàng yến",
      },
      {
        label: "Hàng có pin - cồn - cháy nổ",
        value: "Hàng có pin - cồn - cháy nổ",
      },
      {
        label: "Hàng thương hiệu",
        value: "Hàng thương hiệu",
      },
      {
        label: "Hàng thực phẩm",
        value: "Hàng thực phẩm",
      },
      {
        label: "Hàng mỹ phẩm",
        value: "Hàng mỹ phẩm",
      },
      {
        label: "Hàng thuốc tây - thuốc nam - TPCN dạng viên",
        value: "Hàng thuốc tây - thuốc nam - TPCN dạng viên",
      },
      {
        label: "Hàng quần áo - đồ gia dụng",
        value: "Hàng quần áo - đồ gia dụng",
      },
      {
        label: "Hàng đông lạnh",
        value: "Hàng đông lạnh",
      },
      {
        label: "Hàng cây cảnh",
        value: "Hàng cây cảnh",
      },
      {
        label: "Hàng làm từ da động vật",
        value: "Hàng làm từ da động vật",
      }
    ];

    const statusTypeOption = {
      PROGRESS: "Chưa làm chứng từ",
      DONE: 'Đã làm chứng từ'
    }
    const statusPaymentOption = {
      NOTYET: "Chưa thanh toán",
      PAID: 'Đã thanh toán',
      DEBT: 'Công nợ'
    }
    
    var packTable = ''
    const CountriesOptions = Country.getAllCountries().map((country) => ({
      value: country.name + ' - ' + country.isoCode,
      label: country.name + ' - ' + country.isoCode,
    }))
    const CitiesOptions = this.state.billInfo.receiverCountry
      ? City.getCitiesOfCountry(
          this.state.billInfo.receiverCountry.split(' - ')[1],
        ).map((city) => ({ value: city.name, label: city.name }))
      : []
    if (this.state.billInfo.packageType === PACK_TYPE) {
      packTable = (
        <div>
          <Row>
            <Col xs="12" className="mt-3">
              <Card>
                <CardHeader tag="h5">
                  <span className="fw-semi-bold">PACK DETAILS</span>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <p style={{ color: 'red' }}>
                        {' '}
                        *Chú ý: Gross Weight và Dimension phải được làm tròn
                      </p>
                      <p>
                        - Làm tròn 0,5kg đối với những kiện hàng dưới 30kg.
                        <br />
                        Ví dụ: 25.1kg làm tròn 25.5kg hoặc 25.6kg làm tròn 26kg
                        <br />
                        - Làm tròn 1kg đối với những kiện hàng trên 30kg
                        <br />
                        Ví dụ: 30.1kg -{'>'} 30.9kg làm tròn 31kg
                      </p>
                    </Col>
                    <Col className="text-right">
                      <Label>
                        {' '}
                        Total Number of Packs: &nbsp;{' '}
                        {this.state.packList.reduce(function (a, b) {
                          return a + parseInt(b['quantity'])
                        }, 0) || 0}
                      </Label>{' '}
                      <br />
                      <Label>
                        {' '}
                        Total Charged Weight: &nbsp;{' '}
                        {Rounding(
                          this.state.packList.reduce(function (a, b) {
                            return a + parseFloat(b['chargedWeight'])
                          }, 0),
                        ) || 0}{' '}
                      </Label>{' '}
                      <br />
                      <br />
                      {this.state.packsDetailsValidation.packs ? (
                        <p style={{ color: 'red' }}>
                          {' '}
                          *Please input valid package details
                        </p>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>

                  <Table
                    responsive
                    className="table-bordered table-sm"
                    width="100%"
                  >
                    <thead className="text-uppercase table-light">
                      <tr>
                        <th className="text-center">Quantity</th>
                        <th className="text-center">Type</th>
                        <th className="text-center">Length (cm)</th>
                        <th className="text-center">Width (cm)</th>
                        <th className="text-center">Height (cm)</th>
                        <th className="text-center">Weight (kg)</th>
                        <th className="text-center">Converted Weight</th>
                        <th className="text-center">Charged Weight</th>
                        <th className="text-center">Upload Image</th>
                        <th className="text-center">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.packList && this.state.packList.map((x, i) => {
                        return (
                          <tr>
                            <td className="text-center">
                              <div className="input-group input-group-sm">
                                <Input
                                  className="form-control quantity"
                                  name="quantity"
                                  value={x.quantity}
                                  invalid={
                                    this.state.packsDetailsValidation[i] &&
                                    this.state.packsDetailsValidation[i]
                                      .quantity
                                  }
                                  type="number"
                                  id="quantity"
                                  onChange={(e) => this.onPackInfoChange(e, i)}
                                />
                              </div>
                            </td>
                            <td>
                              <Input
                                placeholder="Loại"
                                name="type"
                                className="form-control"
                                type="text"
                                value={x.type}
                                onChange={(e) => this.onPackInfoChange(e, i)}
                                list="typeList"
                              />
                              <datalist id="typeList">
                                <option value="carton">Carton</option>
                                <option value="pallet">Pallet</option>
                                <option value="tui">Túi (Phong bì)</option>
                              </datalist>
                            </td>

                            <td className="text-center">
                              <div className="input-group input-group-sm ">
                                <Input
                                  name="length"
                                  value={x.length}
                                  type="number"
                                  id="chieudai"
                                  invalid={
                                    this.state.packsDetailsValidation[i] &&
                                    this.state.packsDetailsValidation[i].length
                                  }
                                  onChange={(e) => this.onPackInfoChange(e, i)}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="input-group input-group-sm ">
                                <Input
                                  name="width"
                                  value={x.width}
                                  type="number"
                                  id="chieurong"
                                  invalid={
                                    this.state.packsDetailsValidation[i] &&
                                    this.state.packsDetailsValidation[i].width
                                  }
                                  onChange={(e) => this.onPackInfoChange(e, i)}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="input-group input-group-sm ">
                                <Input
                                  name="height"
                                  value={x.height}
                                  type="number"
                                  id="chieucao"
                                  invalid={
                                    this.state.packsDetailsValidation[i] &&
                                    this.state.packsDetailsValidation[i].height
                                  }
                                  onChange={(e) => this.onPackInfoChange(e, i)}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="input-group input-group-sm">
                                <Input
                                  type="number"
                                  name="weight"
                                  value={x.weight}
                                  id="trongluong"
                                  invalid={
                                    this.state.packsDetailsValidation[i] &&
                                    this.state.packsDetailsValidation[i].weight
                                  }
                                  onChange={(e) => this.onPackInfoChange(e, i)}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <label id="tlquydoi"> {x.convertRatio} </label>
                            </td>
                            <td className="text-center">
                              <label id="chargedweight">
                                {' '}
                                {x.chargedWeight}{' '}
                              </label>
                            </td>
                            <div className="input-group input-group-sm">
                              <Input
                                type="file"
                                name="images"
                                id="images"
                                accept="image/*"
                                onChange={(e) =>
                                  this.onImageChange(e, i, "packList")
                                }
                              />
                            </div>
                            <td className="text-center">
                              <button
                                type="button"
                                className="btn btn-default"
                                aria-label="glyphicon-trash"
                                onClick={() => this.onRemoveRecord(i)}
                              >
                                <span
                                  className="glyphicon glyphicon-trash"
                                  aria-hidden="true"
                                ></span>
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                  <div className="text-center">
                    <Button
                      type="button"
                      id="plus"
                      className="btn btn-default"
                      aria-label="Left Align"
                      onClick={this.addPackage}
                    >
                      <span
                        className="glyphicon glyphicon-plus"
                        aria-hidden="true"
                      ></span>
                      Thêm Kiện hàng
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" className="mt-3">
              <Card>
                <CardHeader tag="h5">
                  <span className="fw-semi-bold">Invoice</span>
                </CardHeader>
                <CardBody>
                  <FormGroup name="invoiceExportFormat" className="form-inline">
                    <Label id="invoiceExportFormat" name="invoiceExportFormat">
                      Export as: &nbsp;{' '}
                    </Label>
                    <Input
                      placeholder="Choose"
                      name="invoiceExportFormat"
                      className="form-control"
                      type="text"
                      value={this.state.billInfo.invoiceExportFormat}
                      onChange={(e) => this.onInfoChange(e)}
                      list="invoiceExportForms"
                    />
                    <datalist id="invoiceExportForms">
                      <option value="Gift (no commercial value)" />
                      <option value="Sample" />
                      <option value="Other" />
                    </datalist>
                  </FormGroup>
                  <Table responsive className="table-bordered table-sm mb-0">
                    <thead className="text-uppercase table-light">
                      <tr>
                        <th className="text-center">
                          Goods Details
                          <br />
                          (Product names, materials, stamps, ...)
                        </th>
                        <th className="text-center">Quantity</th>
                        <th className="text-center">Unit</th>
                        <th className="text-center">Price</th>
                        <th className="text-center">Total Value</th>
                        <th className="text-center">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.packInvoiceList.map((x, i) => {
                        return (
                          <tr>
                            <td className="text-center">
                              <div className="input-group">
                                <Input
                                  name="description"
                                  value={x.description}
                                  type="textarea"
                                  className="description"
                                  id="description"
                                  onChange={(e) => this.onChangeInvoice(e, i)}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="input-group flex-nowrap">
                                <Input
                                  name="quantity"
                                  value={x.quantity}
                                  type="number"
                                  className="quantity"
                                  id="quantity"
                                  placeholder="quantity"
                                  onChange={(e) => this.onChangeInvoice(e, i)}
                                />
                              </div>
                            </td>
                            <td>
                              <Input
                                placeholder="Choose"
                                name="unit"
                                className="form-control"
                                type="text"
                                value={x.unit}
                                onChange={(e) => this.onChangeInvoice(e, i)}
                                list="unitList"
                              />
                              <datalist id="unitList">
                                <option value="Bag" />
                                <option value="Pcs" />
                                <option value="Box" />
                                <option value="Jar" />
                                <option value="Set" />
                                <option value="Other" />
                              </datalist>
                            </td>
                            <td className="text-center">
                              <div className="input-group flex-nowrap">
                                <Input
                                  name="unitPrice"
                                  value={x.unitPrice}
                                  type="number"
                                  className="unitPrice"
                                  id="unitPrice"
                                  onChange={(e) => this.onChangeInvoice(e, i)}
                                />
                              </div>
                            </td>
                            <td name="subTotal" className="text-center">
                              {parseFloat(x.subTotal || 0).toFixed(1)}
                            </td>
                            <td className="text-center">
                              <button
                                type="button"
                                className="btn btn-default"
                                aria-label="glyphicon-trash"
                                onClick={() =>
                                  this.onRemovePackInvoiceRecord(i)
                                }
                              >
                                <span
                                  className="glyphicon glyphicon-trash"
                                  aria-hidden="true"
                                ></span>
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>

                  <br />
                  <div className="text-center">
                    <Button
                      type="button"
                      id="plus"
                      className="btn btn-default"
                      aria-label="Left Align"
                      onClick={this.addPackageInvoice}
                    >
                      <span
                        className="glyphicon glyphicon-plus"
                        aria-hidden="true"
                      ></span>
                      Thêm
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )
    }

    return (
      <div>
        <Form className="needs-validation">
          <div className={s.root} id="BillForm">
            <Breadcrumb>
              <BreadcrumbItem>TRANG</BreadcrumbItem>
              <BreadcrumbItem active>Hoá đơn</BreadcrumbItem>
            </Breadcrumb>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
            />
            <Row>
              <Col lg="3">
                <Card>
                  <CardHeader tag="h5">Thông tin Người gửi (Sender)</CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label for="senderCompanyName">
                        Công ty (Company Name){' '}
                        <span className={s.required}>*</span>
                      </Label>
                      <Input
                        id="senderCompanyName"
                        name="senderCompanyName"
                        type="text"
                        value={this.state.billInfo.senderCompanyName}
                        invalid={this.state.billValidation.senderCompanyName}
                        onChange={(e) => this.onInfoChange(e)}
                      />
                      <FormFeedback>
                        {this.state.billValidation.senderCompanyName &&
                          'Please input valid company name'}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="senderName">
                        Người LH (Contact Name){' '}
                        <span className={s.required}>*</span>
                      </Label>
                      <Input
                        id="senderName"
                        name="senderName"
                        type="text"
                        value={this.state.billInfo.senderName}
                        invalid={this.state.billValidation.senderName}
                        onChange={(e) => this.onInfoChange(e)}
                      />
                      <FormFeedback>
                        {this.state.billValidation.senderName &&
                          'Please input valid contract name'}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="senderContact">
                        Địa chỉ Liên hệ (Contact address){' '}
                        <span className={s.required}>*</span>
                      </Label>
                      <Input
                        id="senderContact"
                        name="senderContact"
                        type="text"
                        value={this.state.currUser?.address}
                        invalid={this.state.billValidation.senderAddress}
                        onChange={(e) => this.onInfoChange(e)}
                        disabled
                      />
                      <FormFeedback>
                        {this.state.billValidation.senderAddress &&
                          'Please input valid contract address'}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="senderPhone">
                        Số Điện thoại (Telephone){' '}
                        <span className={s.required}>*</span>
                      </Label>
                      <Input
                        id="senderPhone"
                        name="senderPhone"
                        type="text"
                        value={this.state.billInfo.senderPhone}
                        invalid={this.state.billValidation.senderPhone}
                        onChange={(e) => this.onInfoChange(e)}
                      />
                      <FormFeedback>
                        {this.state.billValidation.senderPhone &&
                          'Please input valid phone'}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="senderEmail">Email</Label>
                      <Input
                        id="senderEmail"
                        name="senderEmail"
                        type="email"
                        value={this.state.billInfo.senderEmail}
                        invalid={this.state.billValidation.senderEmail}
                        onChange={(e) => this.onInfoChange(e)}
                      />
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" md="12">
                <Card>
                  <CardHeader tag="h5">
                    Thông tin Người nhận (Receiver)
                  </CardHeader>
                  <CardBody>
                    {
                      (this.state.currUser.role !== USER_ROLE.OPS &&
                        this.state.currUser.role !== USER_ROLE.DOCUMENT) && (
                        <FormGroup>
                          <Label for="logisticService">Khách Hàng - KHTP{this.state.editBillup?.customerId}</Label>
                          <Select
                            options={this.state.listCustomerKH}
                            value={this.state.selectedCustomerKH?.[0]?.id}
                            onChange={this.onSelectedCustomerKH}
                            onInputChange={this.handleInputChangeKH}
                            isSearchable
                            autosize={false}/>
                        </FormGroup>
                      )
                    }
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="receiverCompanyName">
                            Công ty (Company Name){' '}
                            {/*<span className={s.required}>*</span>*/}
                          </Label>
                          <Input
                            id="receiverCompanyName"
                            name="receiverCompanyName"
                            type="text"
                            value={this.state.billInfo.receiverCompanyName}
                            invalid={
                              this.state.billValidation.receiverCompanyName
                            }
                            onChange={(e) => this.onInfoChange(e)}
                          />
                          <FormFeedback>
                            {this.state.billValidation.receiverCompanyName &&
                              "Please input valid receiver company's name"}
                          </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                          <Label for="receiverName">
                            Người Liên hệ (Contact Name){' '}
                            <span className={s.required}>*</span>
                          </Label>
                          <Input
                            id="receiverName"
                            name="receiverName"
                            type="text"
                            value={this.state.billInfo.receiverName}
                            invalid={this.state.billValidation.receiverName}
                            onChange={(e) => this.onInfoChange(e)}
                          />
                          <FormFeedback>
                            {this.state.billValidation.receiverName &&
                              'Please input valid receiver name'}
                          </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                          <Label for="receiverPhone">
                            Số Điện thoại (Telephone){' '}
                            <span className={s.required}>*</span>
                          </Label>
                          <Input
                            id="receiverPhone"
                            name="receiverPhone"
                            type="text"
                            value={this.state.billInfo.receiverPhone}
                            invalid={this.state.billValidation.receiverPhone}
                            onChange={(e) => this.onInfoChange(e)}
                          />
                          <FormFeedback>
                            {this.state.billValidation.receiverPhone &&
                              'Please input valid receiver phone'}
                          </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                          <Label for="receiverCountry">
                            Quốc gia (Country){' '}
                            <span className={s.required}>*</span>
                          </Label>
                          <Select
                            id="receiverCountry"
                            name="receiverCountry"
                            value={CountriesOptions.find(
                              (o) =>
                                o.value === this.state.billInfo.receiverCountry,
                            )}
                            options={CountriesOptions}
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                borderColor: this.state.billValidation
                                  .receiverCountry
                                  ? 'red'
                                  : styles.borderColor,
                                height: 'calc(1.5em + 0.75rem + 2px)',
                                borderRadius: 0,
                              }),
                            }}
                            onChange={(value, action) =>
                              this.onSelectChange(value, action)
                            }
                          />
                          <div className={s.error}>
                            {this.state.billValidation.receiverCountry &&
                              'Please input valid receiver country'}
                          </div>
                        </FormGroup>

                      </Col>

                      <Col>
                        <FormGroup>
                          <Label for="receiverPostalCode">
                            Mã Bưu chính (Postal code){' '}
                            <span className={s.required}>*</span>
                          </Label>
                          <Input
                            id="receiverPostalCode"
                            name="receiverPostalCode"
                            type="text"
                            value={this.state.billInfo.receiverPostalCode}
                            invalid={
                              this.state.billValidation.receiverPostalCode
                            }
                            onChange={(e) => this.onInfoChange(e)}
                          />
                          <FormFeedback>
                            {this.state.billValidation.receiverPostalCode &&
                              'Please input valid receiver postal code'}
                          </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="receiverCity">
                            Thành phố (City){' '}
                            <span className={s.required}>*</span>
                          </Label>
                          <FormFeedback>
                            {this.state.billValidation.receiverCity &&
                              'Please input valid receiver city'}
                          </FormFeedback>
                          <Select
                            id="receiverCity"
                            name="receiverCity"
                            value={CitiesOptions.find(
                              (o) =>
                                o.value === this.state.billInfo.receiverCity,
                            )}
                            options={CitiesOptions}
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                borderColor: this.state.billValidation
                                  .receiverCity
                                  ? 'red'
                                  : styles.borderColor,
                                height: 'calc(1.5em + 0.75rem + 2px)',
                                borderRadius: 0,
                              }),
                            }}
                            onChange={(value, action) =>
                              this.onSelectChange(value, action)
                            }
                          />
                          <div className={s.error}>
                            {this.state.billValidation.receiverCity &&
                              'Please input valid receiver city'}
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label for="receiverProvince">
                            Tỉnh (State/Province)
                          </Label>
                          <Input
                            id="receiverProvince"
                            name="receiverProvince"
                            value={this.state.billInfo.receiverProvince}
                            type="text"
                            onChange={(e) => this.onInfoChange(e)}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label for="receiverAddr1">
                            Địa chỉ{' '}
                            <small className={s.required}> KHÔNG NHẬP POSTCODE, STATE, CITY</small>
                          </Label>
                          <Input
                            id="receiverAddr1"
                            name="receiverAddr1"
                            type="text"
                            value={this.state.billInfo.receiverAddr1}
                            invalid={this.state.billValidation.receiverAddr1}
                            onChange={(e) => this.onInfoChange(e)}
                          />
                          <FormFeedback>
                            {this.state.billValidation.receiverAddr1 &&
                              'Please input valid receiver address 1'}
                          </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                          <Label for="receiverAddr2">
                            Địa chỉ đầy đủ{' '}
                          </Label>
                          <Input
                            id="receiverAddr2"
                            name="receiverAddr2"
                            type="text"
                            value={this.state.billInfo.receiverAddr2}
                            invalid={this.state.billValidation.receiverAddr2}
                            onChange={(e) => this.onInfoChange(e)}
                          />
                          <FormFeedback>
                            {this.state.billValidation.receiverAddr2 &&
                              'Please input valid receiver address 2'}
                          </FormFeedback>
                        </FormGroup>

                        {/*<FormGroup>*/}
                        {/*  <Label for="receiverAddr3">Địa chỉ (address) 3</Label>*/}
                        {/*  <Input*/}
                        {/*    id="receiverAddr3"*/}
                        {/*    name="receiverAddr3"*/}
                        {/*    type="text"*/}
                        {/*    onChange={(e) => this.onInfoChange(e)}*/}
                        {/*  />*/}
                        {/*</FormGroup>*/}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3">
                <Card>
                  <CardHeader tag="h5">
                    Thông tin Đơn hàng (Shipment Info)
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label for="logisticService">
                        Dịch vụ vận chuyển (Services){' '}
                        <span className={s.required}>*</span>
                      </Label>
                      <Input
                        placeholder="Chọn hoặc nhập"
                        name="logisticService"
                        type="select"
                        id="logisticService"
                        value={this.state.billInfo.logisticService}
                        onChange={(e) => this.onInfoChange(e)}
                        list="logisticServiceList"
                      >
                        {this.state.listServices?.map(i => (<option value={i.name}> {i.name} </option>))}
                      </Input>
                        <div className={s.error}>
                        {this.state.billValidation.logisticService &&
                          'Please input valid services'}
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label for="referenceCode">Ref Code</Label>
                      <Input
                        type="text"
                        id="referenceCode"
                        value={this.state.billInfo.referenceCode}
                        name="referenceCode"
                        onChange={(e) => this.onInfoChange(e)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="referenceCode">Hawb Code</Label>
                      <Input
                        type="text"
                        id="hawbCode"
                        value={this.state.billInfo.hawbCode}
                        name="hawbCode"
                        onChange={(e) => this.onInfoChange(e)}
                      />
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* Billup ìnfo */}

                <Card>
                  <CardHeader tag="h5">Thông tin Bill</CardHeader>
                  <CardBody>
                    <Row>
                      <Col lg="4" md="12" className="mb-4">
                        <FormGroup>
                          <Label for="moneyNumber">Cân nặng</Label>
                          <div className="input-group">
                            <Input type="number" name="weight" id="weight" value={this.state.editBillup?.weight} placeholder=""
                                   onChange={this.onEditBillupChange}/>
                            <div className="input-group-append">
                              <span className="input-group-text">(Kg) </span>
                            </div>
                          </div>

                        </FormGroup>
                        <FormGroup>
                          <Label for="moneyNumber">Cân nặng quy đổi</Label>
                          <div className="input-group">
                            <Input type="number" name="weight2" id="weight2" value={this.state.editBillup?.weight2} placeholder=""
                                   onChange={this.onEditBillupChange}/>
                            <div className="input-group-append">
                              <span className="input-group-text">(Kg) </span>
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup row tag="fieldset" style={{ padding: '0 15px' }}>
                          <Label>Loại (Type) &nbsp; &nbsp;</Label>
                          <FormGroup check>
                            <Input
                              name="doc"
                              type="radio"
                              value={DOC_TYPE}
                              checked={this.state.billInfo.packageType === DOC_TYPE}
                              onChange={this.handlePackageTypeChange}
                            />
                            <Label check>
                              <b>DOC &nbsp; &nbsp;</b>
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Input
                              name="pack"
                              type="radio"
                              value={PACK_TYPE}
                              checked={
                                this.state.billInfo.packageType === PACK_TYPE
                              }
                              onChange={this.handlePackageTypeChange}
                            />
                            <Label check>
                              <b>PACK</b>
                            </Label>
                          </FormGroup>
                        </FormGroup>

                        <FormGroup>
                          <Label for="packContent">
                            Tên Hàng (content){' '}
                            <span className={s.required}>*</span>
                          </Label>
                          <Input
                            type="text"
                            id="packContent"
                            value={this.state.billInfo.packContent}
                            name="packContent"
                            invalid={this.state.billValidation.packContent}
                            onChange={(e) => this.onInfoChange(e)}
                          />
                          <FormFeedback>
                            {this.state.billValidation.packContent > 0 &&
                              'Please input valid pack content'}
                          </FormFeedback>
                        </FormGroup>

                        {(this.state.billInfo.packageType === PACK_TYPE && (
                            <>

                              <FormGroup>
                                <Label for="packInvoiceValue">
                                  Giá trị khai báo (invoice value)
                                </Label>
                                <div className="input-group">
                                  <Input
                                    type="number"
                                    id="packInvoiceValue"
                                    enable="false"
                                    value={this.state.billInfo.packInvoiceValue}
                                    name="packInvoiceValue"
                                    invalid={
                                      this.state.billValidation.packInvoiceValue
                                    }
                                    onChange={(e) => this.onInfoChange(e)}
                                  />
                                  <div className="input-group-append">
                                    <button
                                      className="btn btn-outline-secondary btn-sm"
                                      type="button"
                                      id="btn_gtri"
                                      onClick={(e) => this.getInvoiceVal(e)}
                                    >
                                      GET
                                    </button>
                                    <span className="input-group-text">USD</span>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          )) ||
                          (this.state.billInfo.packageType === DOC_TYPE && (
                            <>
                              <FormGroup>
                                <Label for="docCount">
                                  Số Kiện (Number of Packages){' '}
                                  <span className={s.required}>*</span>
                                </Label>
                                <Input
                                  type="number"
                                  id="docCount"
                                  value={this.state.billInfo.docCount}
                                  name="docCount"
                                  invalid={this.state.billValidation.docCount}
                                  onChange={(e) => this.onInfoChange(e)}
                                />
                                <FormFeedback>
                                  {this.state.billValidation.docCount > 0 &&
                                    'Please input valid doc count'}
                                </FormFeedback>
                              </FormGroup>

                              <FormGroup>
                                <Label for="docWeight">
                                  Cân nặng (GrossWeight){' '}
                                  <span className={s.required}>*</span>
                                </Label>
                                <div className="input-group">
                                  <Input
                                    type="number"
                                    id="docWeight"
                                    value={this.state.billInfo.docWeight}
                                    name="docWeight"
                                    invalid={this.state.billValidation.docWeight}
                                    onChange={(e) => this.onInfoChange(e)}
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">(Kg) </span>
                                  </div>
                                  <FormFeedback>
                                    {this.state.billValidation.docWeight > 0 &&
                                      'Please input valid doc weight'}
                                  </FormFeedback>
                                </div>
                              </FormGroup>
                            </>
                          ))}
                      </Col>
                      {
                        (this.state.currUser?.role !== 'ops' && this.state.currUser?.role !== 'document' ) && (
                          <Col lg="4" md="12" className="mb-4">
                            <FormGroup>
                              <Label for="moneyNumber">Số tiền thu khách</Label>
                              <Input type="number" name="totalPrice" id="totalPrice"
                                     value={this.state.billInfo?.totalPrice}
                                     placeholder="Số tiền thu khách"
                                     onChange={this.onEditBillupChange}/>
                            </FormGroup>
                            <FormGroup>
                              <Label for="moneyNumber">Số tiền bảo hiểm</Label>
                              <Input type="number" name="bhPrice" id="bhPrice" value={this.state.editBillup?.bhPrice} placeholder="Số tiền bảo hiểm"
                                     onChange={this.onEditBillupChange}/>
                            </FormGroup>
                            <FormGroup>
                              <Label for="typeGood">Nhóm hàng</Label>
                              {/*<Input id="group" name="group"*/}
                              {/*       value={this.state.editBillup?.group}*/}
                              {/*       type="select" onChange={this.onEditBillupChange}>*/}
                              {/*  {Object.values(groupTypeOption).map((object, i) => <option className="text-capitalize"*/}
                              {/*                                                             value={object}*/}
                              {/*                                                             key={i}>{object}</option>)}*/}
                              {/*</Input>*/}

                              <Select
                                defaultValue={[]}
                                value={this.state.groupSelected}
                                isMulti
                                name="colors"
                                options={groupTypeOption2}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e) => this.handleChangeType(e)}
                              />
                            </FormGroup>

                          </Col>
                        )
                      }
                      {
                        (this.state.currUser?.role !== 'ops' && this.state.currUser?.role !== 'document' ) && (


                          <Col lg="4" md="12" className="mb-4">
                            {
                              (this.state.currUser?.role === 'admin' || this.state.currUser?.role === 'accountant') && (
                                <FormGroup>
                                  <Label for="paymentType">Tình trạng thanh toán</Label>
                                  <Input id="reportStatus" name="reportStatus"
                                         value={this.state.billInfo.reportStatus}
                                         type="select" onChange={this.onInfoChange}>
                                    {Object.values(statusPaymentOption).map((object, i) => <option className="text-capitalize"
                                                                                                   value={object}
                                                                                                   key={i}>{object}</option>)}
                                  </Input>
                                </FormGroup>
                                )
                            }

                            <FormGroup>
                              <Label for="paymentType">Hình thức thanh toán</Label>
                              <Input id="paymentType" name="paymentType"
                                     value={this.state.editBillup?.paymentType}
                                     type="select" onChange={this.onEditBillupChange}>
                                {Object.values(paymentTypeOption).map((object, i) => <option className="text-capitalize"
                                                                                             value={object}
                                                                                             key={i}>{object}</option>)}
                              </Input>
                            </FormGroup>
                            <FormGroup>
                              <Label for="note">Ghi chú</Label>
                              <textarea
                                className="form-control"
                                rows="4"
                                id="note"
                                value={this.state.editBillup?.note}
                                placeholder="Ghi chú"
                                onChange={this.onEditBillupChange}
                                name="note"
                              />
                            </FormGroup>
                          </Col>
                        )
                      }
                    </Row>
                  </CardBody>

                </Card>

            
            {packTable}

            <Row>
              <div className="container mt-3">
                <div className="form-check d-flex justify-content-center  mb-3">
                  <div>
                    {' '}
                    <input
                      name="checkDk"
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                  </div>
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Tôi đã đọc, hiểu và đồng ý với các{' '}
                    <Link to="/app/tou" target="_blank">
                      {' '}
                      điều khoản sử dụng dịch vụ
                    </Link>
                  </label>
                  <small className="form-text  text-danger">
                    {this.state.validDkMess}
                  </small>
                </div>
              </div>
            </Row>

            <div className="row">
              <div className="container mt-3">
                <div className="input-group d-flex justify-content-center mb-3">
                  <span>
                    {' '}
                    <Button
                      disabled={this.state.disableSubmitBtn}
                      color="success"
                      size="lg"
                      type="button"
                      className="butt btn-block"
                      onClick={(e) => this.onSubmit(e)}
                    >
                      Cập nhật hoá đơn
                    </Button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isCreating: state.packages.isCreating,
    package: state.packages.package,
    errMsg: state.packages.message,
    type: state.packages.type,
  }
}

export default connect(mapStateToProps)(NewUpdateOrder)
