export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';

export const DOC_TYPE = "doc"
export const PACK_TYPE = "pack"

export const USER_ROLE = {
  ADMIN: "admin",
  ACCOUNTANT: "accountant",
  DOCUMENT: "document",
  CUSTOMER: "customer",
  OPS: "ops",
  SALER: "saler",
  MANAGER: "manager",
  CONSULTANT: "Tư vấn viên",
  SHIPPER: "NV giao nhận",
}

export const USER_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  WAITING_FOR_APPOVE: "WAITING_FOR_APPOVE",
}

export const PACKAGE_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  WAITING_FOR_APPOVE: "WAITING_FOR_APPOVE",
}

export const PACKAGE_ACTION = {
  IMPORT: "IMPORT",
  EXPORT: "EXPORT",
  RETURN: "RETURN",
}

export const PAGINATION_DEFAULT = {limit: 20, page: 1}

export const MENU = [
  {
    header: 'Dashboard',
    headerLink: '/app/dashboard',
    glyph: 'KienHangImg',
    isVisible: [USER_ROLE.ADMIN, USER_ROLE.ACCOUNTANT, USER_ROLE.SALER, USER_ROLE.MANAGER, USER_ROLE.OPS, USER_ROLE.CONSULTANT, USER_ROLE.DOCUMENT, USER_ROLE.DOCUMENT]
  },
  {
    header: 'Thông báo',
    headerLink: '/app/main',
    glyph: 'ThongBaoImg',
    isVisible: [USER_ROLE.ADMIN, USER_ROLE.ACCOUNTANT, USER_ROLE.DOCUMENT, USER_ROLE.CUSTOMER, USER_ROLE.OPS, USER_ROLE.SALER, USER_ROLE.CONSULTANT, USER_ROLE.MANAGER, USER_ROLE.SHIPPER]
  },
  {
    header: 'Bảng Giá',
    headerLink: '/app/pricing',
    glyph: 'ThuChiImg',
    isVisible: [USER_ROLE.ADMIN, USER_ROLE.ACCOUNTANT, USER_ROLE.SALER, USER_ROLE.MANAGER, USER_ROLE.CONSULTANT]
  },
  {
    header: 'Kiện hàng',
    headerLink: '/app/packet',
    glyph: 'KienHangImg',
    isVisible: [USER_ROLE.ADMIN, USER_ROLE.ACCOUNTANT, USER_ROLE.DOCUMENT, USER_ROLE.CUSTOMER, USER_ROLE.OPS, USER_ROLE.SALER, USER_ROLE.CONSULTANT, USER_ROLE.MANAGER, USER_ROLE.SHIPPER]
  },
  {
    header: 'Customer',
    headerLink: '/app/pickup/customer',
    glyph: 'NguoiDungImg',
    isVisible: [USER_ROLE.ADMIN, USER_ROLE.CUSTOMER, USER_ROLE.ACCOUNTANT, USER_ROLE.SALER, USER_ROLE.CONSULTANT, USER_ROLE.DOCUMENT, USER_ROLE.MANAGER, USER_ROLE.OPS, USER_ROLE.SHIPPER],
  },
  {
    header: 'Pickup',
    headerLink: '/app/pickup',
    glyph: 'NhapHangImg',
    isVisible: [USER_ROLE.ADMIN, USER_ROLE.CUSTOMER, USER_ROLE.ACCOUNTANT, USER_ROLE.SALER, USER_ROLE.CONSULTANT, USER_ROLE.DOCUMENT, USER_ROLE.MANAGER, USER_ROLE.OPS, USER_ROLE.SHIPPER],
    sub: [
      {
        header: 'Đóng Gói',
        headerLink: '/app/packing',
        glyph: 'KienHangImg',
      },
      {
        header: 'Billup',
        headerLink: '/app/billup',
        glyph: 'HoaDonImg',
      }
    ]
  },
  {
    header: 'Barcode',
    headerLink: '/app/barcode',
    glyph: 'BarCodeImg',
    isVisible: [USER_ROLE.ADMIN, USER_ROLE.ACCOUNTANT, USER_ROLE.DOCUMENT, USER_ROLE.MANAGER, USER_ROLE.SHIPPER],
    sub: [
      {
        header: 'Nhập hàng',
        headerLink: '/app/barcode/import',
        glyph: 'NhapHangImg',
      },
      {
        header: 'Xuất hàng',
        headerLink: '/app/barcode/export',
        glyph: 'XuatHangImg',
      },
      {
        header: 'Trả hàng',
        headerLink: '/app/barcode/return',
        glyph: 'NhapHangImg',
      },
    ]
  },
  {
    header: 'Quản Lý Mawb',
    headerLink: '/app/tracking/management',
    glyph: 'XuatHangImg',
    icon: 'icon5',
    isVisible: [USER_ROLE.ADMIN, USER_ROLE.DOCUMENT]
  },
  {
    header: 'Quản Lý Hàng Xuất',
    headerLink: '/app/mawb-export',
    glyph: 'KienHangImg',
    icon: 'icon5',
    isVisible: [USER_ROLE.ADMIN, USER_ROLE.DOCUMENT]
  },
  {
    header: 'Tính tiền tự động',
    headerLink: '/app/manage-pricing',
    glyph: 'ThuChiImg',
    isVisible: [USER_ROLE.ADMIN],
    sub: [
      {
        header: 'Dịch vụ',
        headerLink: '/app/manage-service',
        glyph: 'HoaDonImg',
      },
      {
        header: 'Phụ thu',
        headerLink: '/app/manage-extracharge',
        glyph: 'HoaDonImg',
      }
    ]
  },
  {
    header: 'Tracking',
    headerLink: '/app/tracking',
    glyph: 'TrackingImg',
    isVisible: [USER_ROLE.ADMIN, USER_ROLE.ACCOUNTANT, USER_ROLE.DOCUMENT, USER_ROLE.CUSTOMER, USER_ROLE.MANAGER, USER_ROLE.OPS]
  },

  // {
  //     header: 'Thu chi',
  //     headerLink: '/app/report',
  //     glyph: 'ThuChiImg',
  //     isVisible: [USER_ROLE.ADMIN, USER_ROLE.ACCOUNTANT, USER_ROLE.MANAGER,USER_ROLE.SALER, USER_ROLE.CONSULTANT, ],
  // },
  {
    header: 'Thu chi NEW',
    headerLink: '/app/report-new',
    glyph: 'ThuChiImg',
    isVisible: [USER_ROLE.ADMIN, USER_ROLE.ACCOUNTANT, USER_ROLE.MANAGER, USER_ROLE.SALER, USER_ROLE.CONSULTANT,],
  },
  {
    header: 'CSKH Report',
    headerLink: '/app/cskh-report',
    glyph: 'ThuChiImg',
    isVisible: [USER_ROLE.ADMIN, USER_ROLE.ACCOUNTANT, USER_ROLE.DOCUMENT, USER_ROLE.MANAGER, USER_ROLE.SALER, USER_ROLE.CONSULTANT,],
  },
  {
    header: 'Người dùng',
    headerLink: '/app/user',
    glyph: 'NguoiDungImg',
    isVisible: [USER_ROLE.ADMIN]
  },
  {
    header: 'Chờ duyệt',
    headerLink: '/app/waiting-approve',
    glyph: 'ChoDuyetImg',
    isVisible: [USER_ROLE.ADMIN]
  },
  /*{
      header: 'Label US',
      headerLink: '/app/label-us',
      glyph: 'LabelUsImg',
      isVisible: [USER_ROLE.ADMIN, USER_ROLE.DOCUMENT ]
  },
  {
      header: 'USPS',
      headerLink: '/app/usps',
      glyph: 'UspsImg',
      isVisible: [USER_ROLE.ADMIN, USER_ROLE.ACCOUNTANT ]
  },*/
]

export const SHIPPING_SERVICES = ['AIR LOCAL US', 'AIR LOCAL CA', 'AIR LOCAL AU', 'AIR LOCAL NZ', 'SEA LOCAL AU', 'SEA LOCAL NZ', 'DHL SIN', 'FEDEX SIN', 'UPS SIN', 'FEDEX VN', 'UPS VN', 'DHL VN', 'LOCAL SIN', 'LOCAL AU', 'LOCAL USA', 'LOCAL EU']

export const CURRENCY = ['AED', 'AUD', 'BDT', 'BHD', 'BND', 'CAD', 'CHF', 'CNY', 'CNP', 'CZK', 'DKK', 'EUR', 'GBP', 'HKD', 'IDR', 'INR', 'JPY', 'KRW', 'KWD', 'LKR', 'NMK', 'MUR', 'MYR', 'NOK', 'NZD', 'PGK', 'PHP', 'PKR', 'RMB', 'RUB', 'SEK', 'SGD', 'THB', 'TWD', 'USD', 'VND', 'ZAR']

export const SHIPPING_SERVICES_SPECIFIC = ['AIR LOCAL AU', 'AIR LOCAL NZ', 'SEA LOCAL AU', 'SEA LOCAL NZ', 'LOCAL AU']

export const SHIPPING_SERVICES_5500 = ['AIR LOCAL US', 'AIR LOCAL CA']

export const WISEWAY_ROUTER = {
  CREATE_LABELS: '/services/shipper/orderLabels'
}

/* USPS gram - money vnd HCM to USA */
export const E_PACKET = {
  "0.05": "236589.8925",
  "0.1": "248976.39",
  "0.15": "266037.0375",
  "0.2": "294445.4825",
  "0.25": "316632.9135",
  "0.3": "349262.875",
  "0.35": "403926.46",
  "0.4": "451858.47",
  "0.45": "483549.80625",
  "0.5": "566400.613125",
  "0.55": "598966.1",
  "0.6": "614096.1",
  "0.65": "629566.1",
  "0.7": "644679.1",
  "0.75": "666626.1",
  "0.8": "689045.7",
  "0.85": "700944",
  "0.9": "725143.5",
  "0.95": "785915.1",
  "1": "804440",
  "1.1": "836944",
  "1.2": "853405.1",
  "1.3": "876064.4",
  "1.4": "884544",
  "1.5": "899658.7",
  "1.6": "899658.7",
  "1.7": "1154640",
  "1.8": "1188677.4",
  "1.9": "1241340",
  "2": "1259904",
  "2.2": "1275978.03125",
  "2.7": "1526187.8775",
  "3.1": "1724462.72375",
  "3.6": "1923256.92",
  "4": "2112702.81625",
  "4.5": "2280855.3625",
  "4.9": "2491075.25875",
  "5.4": "2661824.555",
  "5.8": "2834131.90125",
  "6.3": "3005919.8975",
  "6.7": "3177707.89375",
}
export const USPS_STATE_40 = ["AK", "HI", "GU", "VI", "APO", "FPO"]
export const USPS_POSTCODE_40 = [974, 975, 976, 988, 989]
export const USPS_STATE_110 = ["GUAM"]
