import React from "react";
import {FormGroup, Input, Label, Row} from "reactstrap";
import logo from "../../images/logo.png";
import EditPacketTitle from '../../images/bill-v2.jpg'
import "./pickup.scss";
import Barcode from "react-jsbarcode";
import moment from "moment/moment";
import Rounding from "../../utils/rounding";
class PrintPackages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      billCreator: {},
      mawbInfo: {},
    }
  }
  formatDate = (index) => {
    return moment().subtract(index * 5, 'seconds').format('YYYY/MM/DD HH:mm:ss')
  }

  getItemWeight = (element) => {
    let totalWeight = 0
    const pkgHawb = element.Package.hawbCode
    let hawbIndex = 0
    if (pkgHawb) {
      const arr = pkgHawb?.trim().split(',')
      hawbIndex = arr.findIndex(i => i.trim() == element.hawbCode.trim());
    }
    let packs = JSON.parse(element.Package.packs) || []
    packs.forEach((packInfo, i) => {
      if (packInfo.weight && i === hawbIndex) {
        totalWeight =
          totalWeight + Rounding(packInfo.weight * packInfo.quantity)
      }
    })
    return totalWeight
  }

  getBillCode = (bill) => {
    const mawbInfo = this.props.mawbInfo || {}
    let text = 'SGN' + `${bill.hawbCode}`
    if (mawbInfo.branch === 'HN') {
      text = 'HAN' + `${bill.hawbCode}`
    }
    text = text.replace(/\s/g, '')
    return text
  }
  render() {
    const {billData = []} = this.props;
    return (
      <div className="modal-pickup">
        {billData.map((bill, index) => (
          <div style={{pageBreakAfter: 'always'}}>
            <div className="print-wrapper" style={{height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
              <h1 style={{fontSize: '60px'}}>{bill.citycode || 'SYD'}</h1>
              <div className="barcode">
                <Barcode renderer="image" style={{height: '50px', width: '90%'}} options={{displayValue: false}}  value={this.getBillCode(bill)}/>
                <span>{this.getBillCode(bill)}</span>
              </div>
              <div><strong>Total Weight (KG): {bill.weight || '...'}</strong></div>
              <div><strong>Total Packages: 1</strong></div>
              <div><strong>{this.formatDate(index)}</strong></div>
              <div className='text-uppercase'><strong>{bill.logistics || 'AUSPOST'}</strong></div>
              <div><strong>{bill.service || 'VN - AU'}</strong></div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default PrintPackages;
